.css-hz1bth-MuiDialog-container {
    height: auto !important;
}

.Main {
    display: flex;
    flex-flow: column;
    height: 100vh;
    background-color: #F5F2EF;
}

.topNav {
    width: 100%;
    height: 20vh;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.topNav> :nth-child(1) {
    width: 150px;
    background: url(./resolve-logo-RGB-light.png) left / contain no-repeat;
    height: 150px;
    margin-top: 2vh;
}

.topNav> :nth-child(2) {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.bottomGrid {
    position: absolute;
    height: 50vh;
    top: 50%;
    width: 100%;
    background-color: #122b3b;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.bottomGrid>div>.line {
    width: 65%;
    border: 1px solid lightgray;
    align-self: center;
}

.bottomPanel {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.copyRight {
    top: 100%;
    font-weight: bold;
    color: white;
}

.loginPanel {
    z-index: 1;
}

.Button {
    padding: 10px 50px;
    color: white;
    background-color: #4D6181;
}

.LoginHeader {
    color: #122b3b;
}

a {
    font-size: 15px;
}

a:hover {
    color: white;
    cursor: pointer;
}

// .NavigationBar > nav > a.navbar-brand {
//   background: url(./logo_eldorwal.png) left / contain no-repeat;
//   width: 300px;
//   height: 50px;
// }

.two-factor {
    input {
        width: 45px;
        height: 45px;
        padding: 0;
        font-size: 24px;
        text-align: center;
        margin-right: 12px;
        text-transform: uppercase;
        color: #494949;
        font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
        border: 1px solid #d6d6d6;
        border-radius: 4px;
        background: #fff;
        background-clip: padding-box;
    }
}

.bluelink {
    color: #122b3b;
    text-decoration: none;
    font-weight: bold;
}

.bluelink:hover {
    color: #198cd3;
}

.agreement-container {
    word-wrap: break-word;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 1.42;
    outline: 0;
    overflow-y: auto;
    padding: 12px 15px;
    white-space: pre-wrap;
    width: 100%;
    max-height: 700px;
    overflow: auto;

    p {
        padding: 0;
        margin: 0;
    }

    ol {
        counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    }

    li {
        list-style-type: none;
        padding-left: 1.5em;
        position: relative;
        counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-0;

        &::before {
            content: counter(list-0, decimal) ". ";
        }

        li {
            counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
            counter-increment: list-1;

            &::before {
                content: counter(list-1, lower-alpha) ". ";
            }

            li {
                counter-increment: list-2;
                counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;

                &::before {
                    content: counter(list-2, lower-roman) ". ";
                }
            }
        }
    }
}