html {
  scroll-behavior: smooth;
}

div#welcome {
  font-family: 'Monserrat-Light';
  padding-top: 123px;
  font-size: 18px;
  text-align: left;

  @media (min-width: 768px) {
    body {
      font-size: 20px;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: 'Monserrat-bold';
    color: #122B3B;
    line-height: 1;
  }

  h1 {
    font-size: 40px;
  }

  @media (min-width: 768px) {
    h1 {
      font-size: 54px;
    }
  }

  h2 {
    font-size: 30px;
    line-height: 1.25;
  }

  @media (min-width: 768px) {
    h2 {
      font-size: 40px;
    }
  }

  @media (min-width: 1200px) {
    h2 {
      font-size: 46px;
    }
  }

  h3 {
    line-height: 1.5;
    font-size: 18px;
  }

  @media (min-width: 768px) {
    h3 {
      font-size: 30px;
    }
  }

  h4 {
    color: #D47739;
    font-size: 18px;
  }

  @media (min-width: 768px) {
    h4 {
      font-size: 24px;
    }
  }

  a:not(.btn-primary) {
    color: #000;
    -webkit-transition: color 0.25s ease-out;
    transition: color 0.25s ease-out;
    text-decoration: none;
  }

  a:not(.btn-primary):hover {
    color: #AA5823;
  }

  p {
    line-height: 1.5;
  }

  .color-white {
    color: #fff;
  }

  .color-black {
    color: #000;
  }

  .bg-blue {
    background-color: #225678;
  }

  .bg-blue-dark {
    background-color: #122B3B;
  }

  .bg-F8 {
    background-color: #F8F8F8;
  }

  .bg-EA {
    background-color: #EAE8E8;
  }

  .bg-triangle {
    background-image: url("triangle.png");
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .bg-triangles {
    background-image: url("triangle-pattern.png");
    background-position: 50% 50%;
    background-repeat: repeat;
    background-size: contain;
  }

  hr {
    border: none;
    -webkit-box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.05), 0px 2px 0px 2px rgba(0, 0, 0, 0.025), 0px 3px 0px 3px rgba(0, 0, 0, 0.0125);
    box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.05), 0px 2px 0px 2px rgba(0, 0, 0, 0.025), 0px 3px 0px 3px rgba(0, 0, 0, 0.0125);
  }

  .card {
    border-radius: 20px;
  }

  @media (min-width: 768px) {
    .card {
      border-radius: 40px;
    }
  }

  .btn {
    text-transform: uppercase;
    border: 0;
    border-radius: 0;
    padding: 17px;
    background-color: #225678;
    font-size: 18px;
    line-height: 1;
    font-family: 'Monserrat-bold';
    -webkit-transition: background-color 0.25s ease-out;
    transition: background-color 0.25s ease-out;
  }

  .btn:hover {
    background-color: #173a50;
  }

  .btn-sm {
    padding: 11px 17px;
    font-size: 16px;
  }

  .btn-brown {
    background-color: #D47739;
    border: 2px solid #D47739;
    color: #fff !important;
    padding: 17px;
  }

  .btn-brown:hover {
    background-color: #AA5823;
    border: 2px solid #AA5823;
    color: #fff;
  }

  .btn-brown.btn-outline {
    background-color: #fff;
    color: #D47739 !important;
  }

  .btn-brown.btn-outline:hover {
    background-color: rgba(212, 119, 57, 0.125);
    color: #AA5823;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
  }

  .navbar {
    padding: 0;
  }

  @media (max-width: 767px) {
    .navbar {
      padding: 0 10px;
    }

    .navbar-collapse {
      margin-top: -15px;
    }
  }

  .navbar-brand {
    width: 100px;
    height: auto;
    padding: 20px 0;
  }

  @media (min-width: 992px) {
    .navbar-brand {
      width: 135px;
    }
  }

  .navbar-toggler {
    width: 35px;
    padding: 0;
    border: none;
    border-radius: 0;
    font-size: 0;
    line-height: 0;
  }

  .navbar-toggler-icon {
    background-color: #225678;
    width: 100%;
    height: 4px;
  }

  .navbar-toggler-icon:nth-of-type(2) {
    margin: 8px 0;
  }

  .navbar .btn-brown {
    font-size: 14px;
    padding: 10px;
  }

  .navbar-nav {
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .navbar-nav .nav-item {
    text-align: center;
  }

  .navbar-nav .nav-item.login a {
    color: #D47739;
  }

  @media (max-width: 767px) {
    .navbar-nav .nav-item {
      border-top: 1px solid #EAE8E8;
    }

    .navbar-nav .nav-item:first-child {
      border: none;
    }
  }

  .navbar-nav .nav-link {
    line-height: 1 !important;
    font-family: 'Monserrat-bold' !important;
    color: #000 !important;

    &:hover {
      color: #AA5823 !important;
    }
  }

  .navbar-nav .login.nav-link {
    color: #D47739 !important;

    &:hover {
      color: #D47739 !important;
    }
  }

  .navbar-nav .demo.nav-link {
    color: #fff !important;

    &:hover {
      color: #fff !important;
    }
  }

  .navbar-nav .nav-link.btn-brown {
    padding: 15px 17px;
  }

  @media (min-width: 768px) {
    .navbar-nav .nav-link {
      font-size: 18px;
      padding: 17px 0;
    }

    .navbar-nav .nav-link.btn-brown {
      padding: 12px 15px;
    }
  }

  .bulletpoints {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .bulletpoints li {
    float: left;
    position: relative;
    padding: 10px 15px 10px 0;
  }

  .bulletpoints li::before {
    position: relative;
    top: 4px;
    content: '';
    display: inline-block;
    width: 22px;
    height: 22px;
    margin: 0 10px 0 0;
    background-image: url("icon_checkmark.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
  }

  .bulletpoints li.small {
    font-style: italic;
    font-family: 'Monserrat-bold';
    padding: 0 25px 10px 0;
  }

  .bulletpoints li.separated {
    border-top: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
  }

  footer {
    font-size: 14px;
  }

  @media (max-width: 767px) {
    .row {
      margin-left: 0;
      margin-right: 0;
    }

    .row .row {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .container {
    position: relative;
    width: 1140px;
  }

  .pl-3,
  .px-3 {
    padding-left: 1rem !important;
  }

  @media (min-width: 1200px) {

    .pl-xl-5,
    .px-xl-5 {
      padding-left: 3rem !important;
    }
  }

  @media (min-width: 992px) {

    .mr-lg-3,
    .mx-lg-3 {
      margin-right: 1rem !important;
    }
  }

}


/*# sourceMappingURL=Welcome.css.map */