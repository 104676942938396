$blue: #387FAD;
$yellow: #F8C826;
$green: #47804A;
$red: #D36135;

.notification {
    right: 0;
    padding: 20px 15px;
    top: 20px;
    box-shadow: 2px 2px 8px #cdcdcd;
    border-radius: 5px;
    display: flex;
    min-width: 300px;
    max-width: 100%;
    border-left: 5px solid;
    z-index: 999;

    svg {
        font-size: 35px;
        margin-right: 10px;

        &.close {
            font-size: 20px;
        }
    }

    &.blue {
        background-color: #E9F4FA;
        border-color: $blue;

        svg {
            color: $blue;
        }
    }

    &.yellow {
        background-color: #FFF8E1;
        border-color: $yellow;

        svg {
            color: $yellow;
        }
    }

    &.green {
        background-color: #EAFCEB;
        border-color: $green;

        svg {
            color: $green;
        }
    }

    &.red {
        background-color: #F9ECE7;
        border-color: $red;

        svg {
            color: $red;
        }
    }
}